<template>
</template>

<script>
import {
  defineComponent,
  onMounted,
  useContext
} from "@nuxtjs/composition-api";
import useUiAlert from "~/composables/useUiAlert";

export default defineComponent({
  setup() {
    const { sendAlert } = useUiAlert();
    const { app } = useContext()

    const currentStore = app.$cookies.get("vsf-locale");

    const htmlText = `
      <div">
        <p>Dear Customer,</p>
        <p>Important message regarding carriers during the holidays.</p>
        <p>On December 24, 27, and 31, we can only offer UPS as a carrier.</p>
        <p>Additional details for dropshipping customers:</p>
        <ul style="margin: 10px 0 0 20px; padding: 0;">
            <li style="list-style-type: disc; margin: 5px 0;">Orders placed on 24-12-2024 before 12:00 PM will be processed the same day.</li>
            <li style="list-style-type: disc; margin: 5px 0;">Orders placed on 31-12-2024 before 12:00 PM will be processed the same day.</li>
        </ul>
        <p>Thank you for your cooperation, and on behalf of Shots, we wish you happy holidays.</p>
      </div>
    `
    const htmlTextNl = `
      <div">
        <p>Beste klant,</p>
        <p>Belangrijk bericht omtrent vervoerders tijdens de feestdagen.</p>
        <p>Op 24, 27 en 31 december kunnen wij alleen UPS als vervoerder aanbieden.</p>
        <p>Overige bijzonderheden voor dropshipklanten:</p>
        <ul style="margin: 10px 0 0 20px; padding: 0;">
            <li style="list-style-type: disc; margin: 5px 0;">Bestellingen geplaatst op 24-12-2024 vóór 12:00 uur worden dezelfde dag verwerkt.</li>
            <li style="list-style-type: disc; margin: 5px 0;">Bestellingen geplaatst op 31-12-2024 vóór 12:00 uur worden dezelfde dag verwerkt.</li>
        </ul>
        <p>Bedankt voor uw medewerking en namens Shots wensen wij u prettige feestdagen toe.</p>
      </div>
    `
    const htmlTextDe = `
      <div">
        <p>Sehr geehrter Kunde,</p>
        <p>Wichtige Mitteilung zu den Versanddienstleistern während der Feiertage.</p>
        <p>Am 24., 27. und 31. Dezember können wir nur UPS als Versanddienstleister anbieten.</p>
        <p>Besondere Hinweise für Dropshipping-Kunden:</p>
        <ul style="margin: 10px 0 0 20px; padding: 0;">
            <li style="list-style-type: disc; margin: 5px 0;">Bestellungen, die am 24.12.2024 vor 12:00 Uhr eingehen, werden noch am selben Tag bearbeitet.</li>
            <li style="list-style-type: disc; margin: 5px 0;">Bestellungen, die am 31.12.2024 vor 12:00 Uhr eingehen, werden noch am selben Tag bearbeitet.</li>
        </ul>
        <p>Vielen Dank für Ihre Zusammenarbeit und im Namen von Shots wünschen wir Ihnen frohe Feiertage.</p>
      </div>
    `
    const htmlTextFr = `
      <div">
        <p>Cher client,</p>
        <p>Message important concernant les transporteurs pendant les fêtes.</p>
        <p>Les 24, 27 et 31 décembre, nous ne pourrons proposer que UPS comme transporteur.</p>
        <p>Informations supplémentaires pour les clients dropshipping :</p>
        <ul style="margin: 10px 0 0 20px; padding: 0;">
            <li style="list-style-type: disc; margin: 5px 0;">Commandes passées le 24/12/2024 avant 12h00 seront traitées le jour même.</li>
            <li style="list-style-type: disc; margin: 5px 0;">Commandes passées le 31/12/2024 avant 12h00 seront traitées le jour même.</li>
        </ul>
        <p>Merci pour votre collaboration et, au nom de Shots, nous vous souhaitons de joyeuses fêtes.</p>
      </div>
    `
    const htmlTextEs = `
      <div">
        <p>Estimado cliente,</p>
        <p>Mensaje importante sobre los transportistas durante las fiestas.</p>
        <p>El 24, 27 y 31 de diciembre, solo podremos ofrecer UPS como transportista.</p>
        <p>Detalles adicionales para clientes dropshipping:</p>
        <ul style="margin: 10px 0 0 20px; padding: 0;">
            <li style="list-style-type: disc; margin: 5px 0;">Los pedidos realizados el 24-12-2024 antes de las 12:00 h se procesarán el mismo día.</li>
            <li style="list-style-type: disc; margin: 5px 0;">Los pedidos realizados el 31-12-2024 antes de las 12:00 h se procesarán el mismo día.</li>
        </ul>
        <p>Gracias por su colaboración, y en nombre de Shots, le deseamos unas felices fiestas.</p>
      </div>
    `
    onMounted(() => {
      const today = new Date();
      const expiryDate = new Date('2025-01-01');
      let message = null

      switch (currentStore) {
        case 'en':
          message = htmlText
          break;

        case 'es':
          message = htmlTextEs
          break;

        case 'fr':
          message = htmlTextFr
          break;

        case 'nl':
          message = htmlTextNl
          break;

        case 'de':
          message = htmlTextDe
          break;

        default:
          message = htmlText
          break;
      }

      if (today < expiryDate) {
        setTimeout(() => {
          if (!sessionStorage.getItem(`holidayMessageShown${currentStore}`)) {
            sendAlert({
              icon: "error",
              id: Symbol(`limitqty`),
              message: message,
              persist: true,
              title: "Holiday message",
              type: "danger",
            });
              // Set a flag in sessionStorage to indicate the message has been shown
              sessionStorage.setItem(`holidayMessageShown${currentStore}`, "true");
          }
        }, 1000)
      }
    })
  }
})

</script>